/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import { SimpleGrid } from '@mantine/core'

// import PageHeader from '../components/PageHeader'
import Content from '../components/Content.js'
import Layout from '../components/Layout.js'
import SkipCard from '../components/SkipCard'
import WasteTabs from '../components/WasteTabs'

// Export Template for use in CMS preview
const SkipPageTemplate = ({ data }) => {
  const {
    // title,
    // subTitle,
    // featuredImage,
    body,
    allowedWasteItems,
    restrictedWasteItems
  } = data.directus.skipPage
  const { skips } = data.directus

  const st = skips.map((i) => <SkipCard skip={i} />)

  return (
    <main>
      {/* <PageHeader
        title={title}
        subtitle={subTitle}
        backgroundImage={featuredImage}
      /> */}
      <section className="section">
        <div className="container">
          <Content body={body} />
        </div>
      </section>
      <section className="section">
        <div className="container skinny">
          <SimpleGrid
            spacing="lg"
            breakpoints={[
              { maxWidth: 980, cols: 2, spacing: 'md' },
              { maxWidth: 755, cols: 1, spacing: 'sm' },
              { maxWidth: 600, cols: 1, spacing: 'sm' }
            ]}
            cols={2}
          >
            {st}
          </SimpleGrid>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <WasteTabs
            allowedWasteItems={allowedWasteItems}
            restrictedWasteItems={restrictedWasteItems}
          />
        </div>
      </section>
    </main>
  )
}

const SkipPage = ({ data }) => (
  <Layout meta={data.directus.skipPage.SEO || false}>
    <SkipPageTemplate data={data} />
  </Layout>
)

export default SkipPage

export const pageQuery = graphql`
  query skipPage {
    directus {
      skipPage {
        title
        subTitle
        featuredImage {
          id
        }
        body
        allowedWasteItems {
          title
          featuredImage {
            id
          }
          body
        }
        restrictedWasteItems {
          title
          featuredImage {
            id
          }
          body
        }
      }
      skips {
        skipFeatures {
          description
        }

        title
        featuredImage {
          id
        }
      }
    }
  }
`
