import React from 'react'

import {
  Tabs,
  Title,
  Paper,
  Grid,
  Image,
  Avatar,
  SimpleGrid
} from '@mantine/core'
import { CheckCircle, XCircle } from 'react-feather'
import Content from './Content.js'

function WasteTabs({ allowedWasteItems, restrictedWasteItems }) {
  const allowedItems = allowedWasteItems.map((i) => (
    <WasteCard imageId={i.featuredImage.id} title={i.title} body={i.body} />
  ))
  const restrictedItems = restrictedWasteItems.map((i) => (
    <WasteCard imageId={i.featuredImage.id} title={i.title} body={i.body} />
  ))
  return (
    <Tabs defaultValue="accepted">
      <Tabs.List position="center">
        <Tabs.Tab value="accepted">
          <div style={{ display: 'flex' }}>
            <CheckCircle
              style={{
                color: 'green',
                marginRight: '8px',
                marginTop: '4px'
              }}
            />
            <Title order={3}>Accepted Waste</Title>
          </div>
        </Tabs.Tab>
        <Tabs.Tab value="restricted">
          <div style={{ display: 'flex' }}>
            <XCircle
              style={{
                color: 'red',
                marginRight: '8px',
                marginTop: '4px'
              }}
            />
            <Title order={3}>Restricted Waste</Title>
          </div>
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="accepted" pt="xs">
        <SimpleGrid
          spacing="lg"
          breakpoints={[
            { maxWidth: 980, cols: 2, spacing: 'md' },
            { maxWidth: 755, cols: 1, spacing: 'sm' },
            { maxWidth: 600, cols: 1, spacing: 'sm' }
          ]}
          cols={3}
        >
          {allowedItems}
        </SimpleGrid>
      </Tabs.Panel>
      <Tabs.Panel value="restricted" pt="xs">
        <SimpleGrid
          spacing="lg"
          breakpoints={[
            { maxWidth: 980, cols: 2, spacing: 'md' },
            { maxWidth: 755, cols: 1, spacing: 'sm' },
            { maxWidth: 600, cols: 1, spacing: 'sm' }
          ]}
          cols={3}
        >
          {restrictedItems}
        </SimpleGrid>
      </Tabs.Panel>
    </Tabs>
  )
}

const WasteCard = ({ imageId, body, title }) => {
  return (
    <Paper shadow="xs" p="md" radius="lg" withBorder>
      <Grid>
        <Grid.Col span={4}>
          <Avatar radius="lg" color="blue" size={80}>
            <Image
              src={`https://tfskips.clockworkbee.co.uk/assets/${imageId}?width=120`}
              width={120}
              alt="Norway"
            />
          </Avatar>
        </Grid.Col>
        <Grid.Col span="auto">
          <h3>{title}</h3>
          <Content body={body} />
        </Grid.Col>
      </Grid>
    </Paper>
  )
}
export default WasteTabs
